.page__content__header {
  background-color: var(--wit);
  top: 0px;
  padding-top: 1rem;
  min-height: 8rem;
}

.page__content__header--fixed {
  position: fixed;
}

.page__content__header__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page__content__header__top__goback-container {
  display: flex;
}

.page__content__header__top__title {
  font-size: 3rem;
  font-weight: 500;
}

.page__content__header__top__create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}

.page__content__header__top__create__box {
  padding: 0.5rem 0.75rem;
  margin-left: 1rem;
}

.clickable:hover {
  cursor: pointer;
}
