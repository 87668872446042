/* //////////// */
/* MOBILE FIRST */
/* //////////// */
.checkout {
  padding-bottom: 1rem;
}

.checkout__header {
  margin-bottom: 4rem;
}

.checkout__header__title {
  font-size: 3rem;
}

.checkout-container {
  font-size: 1.2rem;
}

.checkout-container__shopinfo {
  margin-top: 1rem;
  padding: 1rem 2rem;
  background-color: var(--grijs_light);
}

.checkout-container__shopinfo__box__title {
  margin: 1rem 0 0.5rem 0;
  font-weight: 600;
}

.checkout-container__shopinfo__box__field {
  width: 100%;
  border: solid var(--zwart) 2px;
  height: 4rem;
  overflow: hidden;
  color: var(--zwart);
}

.checkout-container__shopinfo__box__confirm {
  width: 100%;
  padding: 1rem;
  border: 1px var(--zwart) solid;
  margin: 1rem 0;
  margin-bottom: 0;
  font-weight: 600;
  background-color: var(--zwart);
  color: var(--wit);
}

/* LIST */
.checkout-container__list {
  border-collapse: collapse;
}

.checkout-container__list__item {
  display: grid;
  grid-template-columns: 30px 120px 1fr;
  align-items: center;
  padding: 0.5rem 1rem;
  text-align: left;
  border-top: solid 1px var(--zwart);
  border-bottom: solid 1px var(--zwart);
  font-size: 0.6rem;
}

.checkout-container__list__item__qty {
  font-weight: 600;
  font-size: 1rem;
}

.checkout-container__list__item__picturebox {
  display: none;
}

.checkout-container__list .checkout-container__list__item:nth-child(even) {
  background-color: var(--grijs_light);
  border-top: none;
  border-bottom: none;
}

.checkoutmodal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;
}

.checkoutmodal-container__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  background-color: var(--blauw_medium);
  color: var(--wit);
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
}

/* /////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .checkout {
    padding-bottom: 1rem;
  }

  .checkout-container__list__item__picturebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkout-container__list__item__picturebox__picture {
    display: block;
    max-height: 40px;
    max-width: 80px;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 996px) {
  .checkout__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkout-container__list {
    grid-area: 1 / 1 / 2 / 6;
  }

  .checkout-container__list__item {
    grid-template-columns: 20px 120px 1fr;
    height: 80px;
    font-size: 0.8rem;
  }

  .checkout-container__list__item__picturebox__picture {
    max-height: 60px;
    max-width: 90px;
  }

  .checkout-container__list__item__qty {
    font-size: 1.2rem;
  }

  .checkout-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 2rem;
  }

  .checkout-container__shopinfo {
    grid-area: 1 / 6 / 2 / 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1rem;
    margin-top: 0;
  }

  .checkoutmodal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}

/* //////////////////////////////////// */
/* TABLET LANDSCAPE == LAP- AND DESKTOP */
/* //////////////////////////////////// */

@media screen and (min-width: 1440px) {
  .checkout__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .checkout-container__list {
    grid-area: 1 / 1 / 2 / 6;
  }

  .checkout-container__list__item {
    grid-template-columns: 30px 240px 1fr;
    height: 80px;
    font-size: 1rem;
  }

  .checkout-container__list__item__picturebox__picture {
    max-height: 60px;
    max-width: 120px;
  }

  .checkout-container__list__item__qty {
    font-size: 1.5rem;
  }

  .checkout-container {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 2rem;
  }

  .checkout-container__shopinfo {
    grid-area: 1 / 6 / 2 / 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .checkoutmodal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}
