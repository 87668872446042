.continue-button {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 1.2rem;
  }
  
  .continue-button__value {
    margin: 0 8px;
    font-weight: 600;
  }
  