/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.itemcard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 3rem 0;
}

.itemcard--list {
  flex-direction: row;
  margin: 0.5rem 0;
  padding: 1rem;
  background-color: var(--grijs_light);
}

.itemcard__ammountinbasket {
  color: var(--groen);
  font-weight: bold;
}

.itemcard__ammountinbasket--list {
  text-align: right;
}

.itemcard__imagewrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--wit);
  height: 500px;
  width: auto;
  overflow: hidden;
  background-color: var(--grijs_light);
}

.itemcard img {
  width: 100%;
}

.itemcard__addBtn {
  display: block;
  width: 100%;
  height: 3rem;
  font-size: 1rem;
  font-weight: 700;
  color: var(--zwart);
  padding: 4px 8px;
  border: 1px var(--zwart) solid;
  text-transform: uppercase;
}

.itemcard__controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
}

.itemcard__controls--list {
  width: 15vw;
}

.itemcard__controls__ammountcontrols {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--grijs_light) inset !important;
}

.itemcard__controls__ammountcontrols__input {
  width: 2rem;
  /* height: 2rem; */
  margin: 0 4px;
  /* border: none; */
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: var(--blauw);
}

.itemcard__title {
  font-weight: 500;
  margin-top: 1rem;
}
.itemcard__title--list {
  margin-top: 0rem;
  width: 35vw;
  font-weight: 600;
}

.itemcard__quantity {
  margin-top: 0.25rem;
}

.item-cross,
.item-minus {
  height: 1.2rem;
  width: 1.2rem;
}

.item-cross:hover,
.item-minus:hover {
  cursor: pointer;
}

.itemcard__modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
}

.itemcard__modal__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--zwart);
  text-align: center;
  color: var(--wit);
  padding: 2rem;
}

.itemcard__modal__box h2 {
  font-size: 1rem;
}

.itemcard__modal__box p {
  font-size: 1rem;
}

.itemcard__modal__box__reasonbutn {
  background-color: var(--zwart);
  width: 100%;
  font-size: 1.2rem;
  border: 1px solid var(--wit);
  margin: 8px;
  padding: 8px 4px;
}

.big-image-modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;
}

.big-image-modal-container__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  color: var(--wit);
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
}

/* /////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .items-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  .items-container--list {
    flex-direction: column;
    gap: 0;
  }

  .itemcard {
    width: 160px;
  }

  .itemcard--list {
    width: 100%;
  }

  .itemcard__subbox--list {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .itemcard__imagewrapper {
    height: 250px;
    width: 160px;
    padding: 2rem;
  }

  .itemcard__imagewrapper--list {
    height: 120px;
    width: 85px;
  }

  .itemcard__modal__box {
    padding: 3rem;
  }

  .itemcard__modal__box h2 {
    font-size: 2rem;
  }

  .itemcard__modal__box p {
    font-size: 1.5rem;
    margin: 1rem 0;
  }

  .itemcard__modal__box__reasonbutn {
    width: 50%;
    font-size: 1.5rem;
  }

  .itemcard__controls__ammountcontrols {
    display: flex;
    align-items: center;
  }

  .itemcard__addBtn {
    width: 50%;
    height: 2rem;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 991px) {
  .itemcard {
    width: 240px;
  }

  .itemcard__imagewrapper {
    height: 340px;
    width: 240px;
  }
  .itemcard__controls__ammountcontrols__input {
    width: 3rem;
    font-size: 1.3rem;
  }
  .big-image-modal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}

/* //////////////// */
/* LAPTOP + BIGGER */
/* //////////////// */

@media screen and (min-width: 1200px) {
  .itemcard {
    width: 320px;
  }

  .items-container {
    justify-content: space-between;
  }

  .itemcard--list {
    width: 100%;
  }

  .itemcard__subbox--list {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .itemcard__imagewrapper {
    height: 420px;
    width: 320px;
  }

  .itemcard__imagewrapper--list {
    height: 120px;
    width: 85px;
  }
  
  .big-image-modal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}

/* //////////////// */
/* MUCH MUCH BIGGER */
/* //////////////// */

@media screen and (min-width: 1800px) {
  .itemcard {
    width: 500px;
  }

  .items-container {
    justify-content: space-between;
  }

  .itemcard--list {
    width: 100%;
  }

  .itemcard__subbox--list {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .itemcard__imagewrapper {
    height: 800px;
    width: 500px;
  }

  .itemcard__imagewrapper--list {
    height: 120px;
    width: 85px;
  }
}
