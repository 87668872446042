/* //////////// */
/* MOBILE FIRST */
/* //////////// */
.modal-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2001;
}

.modal-container__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 250px;
  background-color: var(--blauw_medium);
  color: var(--wit);
  font-size: 1rem;
  text-align: center;
  padding: 1rem;
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 996px) {
  .modal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}

/* //////////////////////////////////// */
/* TABLET LANDSCAPE == LAP- AND DESKTOP */
/* //////////////////////////////////// */

@media screen and (min-width: 1440px) {
  .modal-container__modal {
    width: 500px;
    font-size: 1.2rem;
  }
}
