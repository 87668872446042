/* MAIN FORM STYLING */
.products-form {
  background-color: var(--wit);
  border: 2px solid var(--rood);
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
  background-color: white;
}

.products-form--valid {
  border: 2px solid var(--groen);
}

.products-form--inactive {
  border: 2px solid var(--zwart);
}

.products-form__fields {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  column-gap: 2rem;
  margin-bottom: 2rem;
}

.products-form__actions {
  display: flex;
  align-items: center;
}

.aware-of-trouble {
  font-size: 1.2rem;
}

.aware-of-trouble--checkbox {
  margin-left: 2rem;
  margin-right: 1rem;
}

.aware-of-trouble--checkbox.pickinhouse--checkbox {
  margin-left: 0;
  margin-right: 0;
}

/* INPUTGROUP SPECIFIC STYLES */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--grijs_light) inset !important;
}

.products-form__inputgroup {
  display: flex;
  font-size: 1.2rem;
  flex-direction: column;
  width: 50%;
  text-align: left;
}

.products-form__inputgroup label {
  height: 2rem;
  margin-top: 1rem;
}

.products-form__inputgroup__inputfield {
  font-size: 1rem;
  width: 100%;
  border: none;
  background-color: var(--grijs_light);
  padding: 0.25rem;
}

.products-form__inputgroup__label--error {
  color: var(--rood);
  font-weight: 500;
}

/* CHECKBOX SPECIFIC */
.products-form__inputgroup--checkbox {
  display: inline;
  appearance: auto;
  margin: 0;
  width: 2rem;
}

.products-form__inputgroup__inputfield--checkbox {
  height: 2rem;
  width: 2rem;
  margin: 0;
  background-color: var(--groen);
}
