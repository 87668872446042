/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.home {
  display: block;
}

.home__header {
  margin-top: 1rem;
  width: 100%;
}

.home__header__title {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.home__header__title > span {
  text-transform: capitalize;
}

.home-datagrid {
  color: var(--wit);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.home-datagrid__generalinfo {
  display: none;
}

.home-datagrid__extras,
.home-datagrid__openbackorders {
  display: none;
}

.home-foto {
  overflow: hidden;
  height: 30vh;
  background-color: var(--grijs_light);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-text__header {
  display: flex;
  justify-content: space-between;
}

.home-text__header__description {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--blauw);
}

.home-text__cta {
  margin-left: 0.1rem;
}
.home-text:hover,
.home-foto:hover {
  cursor: pointer;
}

.home-text__cta__title {
  font-size: 2.5rem;
  font-weight: 600;
}

.home-text__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 1rem;
  font-weight: 600;
}

.home__viewall {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background-color: var(--grijs_light);
}

.home__footer {
  display: none;
}

.home-datagrid__extras,
.home-datagrid__openbackorders {
  font-size: 1rem;
}

/* ///////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 641px) {
  .home__header {
    margin-bottom: 2rem;
  }

  .home__header__title {
    font-size: 4rem;
  }

  .home-foto {
    height: 35vh;
  }

  .home__viewall {
    justify-content: flex-end;
    align-items: flex-start;
    background-color: initial;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 991px) {
  .home {
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 11rem auto auto 3rem;
    grid-column-gap: 1rem;
    height: 100%;
    grid-template-areas:
      "hd hd hd hd hd"
      "odg of of of of"
      "odg of of of of"
      "ft ft ft ft ft";
    padding-bottom: 2rem;
  }

  .home__header {
    grid-area: hd;
  }

  .home__header__title {
    font-size: 4rem;
    font-weight: 500;
  }

  .home-datagrid {
    grid-area: odg;
    margin-bottom: 0;
  }

  .home-datagrid__generalinfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(70% - 10px);
    background-color: var(--geel);
    font-size: 1.2rem;
    padding: 1rem;
    margin-bottom: 20px;
  }

  .home-datagrid__extras,
  .home-datagrid__openbackorders {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--groen_medium);
    height: calc(30% - 10px);
    padding: 1rem;
  }

  .home-datagrid__extras:hover,
  .home-datagrid__openbackorders:hover {
    cursor: pointer;
  }

  .home-datagrid__extras__ammountcirkel,
  .home-datagrid__openbackorders__ammountcirkel {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .home-foto {
    grid-area: of;
    height: 100%;
    background-position: center;
  } */

  .home-project-items{
    grid-area: of;
    height: 100%;
  }


  .home-text {
    grid-area: ot;
  }

  .home__viewall {
    grid-area: va;
    align-items: flex-end;
    padding: 0;
  }

  .home__footer {
    grid-area: ft;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .home__footer__infoblock {
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--blauw_light);
  }
}

/* ////// */
/* LAPTOP */
/* ////// */

@media screen and (min-width: 1440px) {
  .home {
    font-size: 3rem;
  }

  .home-datagrid__generalinfo {
    font-size: 1.5rem;
  }

  .home-datagrid__extras,
  .home-datagrid__openbackorders {
    font-size: 1.2rem;
  }
  
  .home-datagrid__extras__ammountcirkel,
  .home-datagrid__openbackorders__ammountcirkel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--wit);
    color: var(--groen_medium);
    margin-bottom: 0.5rem;
  }
}
/* ////////// */
/* BIG SCREEN */
/* ////////// */

@media screen and (min-width: 1920px) {
  .home {
    font-size: 3rem;
  }

  .home__header__title {
    font-size: 5rem;
  }

  .home-datagrid {
    font-size: 3rem;
  }

  .home-datagrid__generalinfo {
    font-size: 3rem;
  }

  .home-text__header__description {
    font-size: 3rem;
  }

  .home-text__cta__title {
    font-size: 5rem;
  }

  .home-text__content {
    font-size: 3rem;
  }

  .home__viewall {
    font-size: 5rem;
  }

  .home__footer__infoblock {
    font-size: 1.5rem;
  }
}
