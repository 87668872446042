/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.translation-manager-list__header {
  display: flex;
  width: 100%;
}

.translation-manager-list__header__cancel-box {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  width: 3rem;
  height: 3rem;
  top: 2rem;
  border-radius: 50%;
  font-size: 2rem;
  background-color: var(--blauw_medium);
  z-index: 5;
}

.translation-manager-list__list__item {
  display: flex;
  position: relative;
  height: 2.5rem;
}

.translation-manager-list__list__item__saving,
.translation-manager-list__list__item__loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  width: 100%;
  height: 100%;
}

.translation-manager-list__header__cell {
  padding: 0.25rem 0.5rem;
  font-size: 0.5rem;
  color: var(--blauw_light);
}

.translation-manager-list__list__item {
  overflow: hidden;
}

.translation-manager-list__list__itemwrapper:nth-child(odd) {
  background-color: var(--grijs_light);
}

.translation-manager-list__list__item__cell {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0.25rem 0.5rem;
  text-align: left;
}

.translation-manager-list__list__item__cell--save {
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0rem;
  height: 100%;
  width: 3rem;
}

.translation-manager-list__list__item__cell--save button {
  color: var(--groen);
  font-size: 1rem;
  font-weight: 500;
  width: 3rem;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--grijs_light) inset !important;
  margin-left: 8px;
}

.translation-manager-list__list__item__cell
  .translation-manager-list__list__item__cell__inputfield {
  display: block !important;
  border: none;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0);
  font-size: 1rem;
  padding: 0;
  margin: 0;
}

.translation-manager-list__list__item__cell__inputfield.translation-manager-list__list__item__cell__inputfield--create {
  background-color: var(--grijs_light);
}

#translation-manager-list__list__item--create {
  background-color: var(--wit);
  border: 2px solid black;
}

#translation-manager-list__list__item--create
  .translation-manager-list__list__item__cell__inputfield {
  display: block !important;
  border: none;
  font-weight: 500;
  width: 100%;
  font-size: 1rem;
  background-color: var(--wit);
  padding: 0;
  margin: 0;
}

/* ////////////////////////////// */
/* LAP- and DESKTOP == BIG SCREEN */
/* ////////////////////////////// */

@media screen and (min-width: 1300px) {
  .translation-manager-list__header__cell,
  .translation-manager-list__list__item__cell {
    font-size: 14px;
    padding: 0.5rem 1rem;
  }

  .translation-manager-list__header__cell {
    font-size: 1rem;
    padding: 0 1rem 1rem 1rem;
  }
}
