.cancel-button {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}

.cancel-button__value {
  margin: 0 8px;
  font-weight: 600;
}

.cancel-button--shake {
  animation: shake-checkout 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake-checkout {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
