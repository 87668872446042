.error-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-container__box {
  gap: 2rem;
  font-size: 1.6rem;
  width: 50%;
  text-align: center;
}

.error-container__box__bigtext {
  font-size: 6rem;
  font-weight: 500;
  color: var(--blauw_medium);
}

.error-container__backbtn {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 3rem;
  padding: 0.25rem 3rem;
  background-color: var(--blauw_light);
  border: solid 1px var(--blauw_light);
}

