.save-button {
  position: relative;
  background-color: var(--rood);
  border-radius: 1rem;
  color: var(--rood_light);
  display: flex;
  align-items: center;
  padding: 0.25rem 2rem;
  margin: 0;
  font-size: 1.5rem;
  z-index: 5000;
  opacity: 0.5;
}

.save-button__value {
  margin: 0 8px;
  font-weight: 600;
}

.save-button--shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  background-color: var(--groen);
  color: white;
  opacity: 1;
}

.save-button--override {
  background-color: var(--blauw);
  color: white;
  opacity: 1;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
