/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.order-detail-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 2rem;
}

.order-detail-table__row td,
.order-detail-table__row th {
  font-size: 10px;
  font-weight: 400;
  text-align: center;
  border-bottom: none;
  padding: 0.25rem;
  height: 2rem;
}

.order-detail-table__row td:first-child {
  padding-left: 2rem;
  text-align: left;
}

.order-detail-table__row td {
  border: solid 2px white;
}

.order-detail-table__row th {
  padding: 1rem;
  color: var(--blauw);
  text-align: center;
}

.order-detail-table__body tr:nth-child(odd) {
  background-color: var(--grijs_light);
}

/* COLUM SPECIFIC */
.order-detail-table__body
  .order-detail-table__row
  .order-detail-table__row__cell:first-child {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.order-detail-table__row .order-detail-table__row__actioncell {
  width: 5%;
  color: var(--blauw);
  background-color: var(--blauw_utlralight);
}
.order-detail-table__row .order-detail-table__row__actioncell__link {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  font-size: 0.8rem;
}

/* GROUP BUTTONS */
.order-detail-table__row__cell__groupbutton {
  background-color: var(--geel);
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-right: 1rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.order-detail-table__row__thumb {
  max-width: 4rem;
  max-height: 8rem;
}

.order-detail-table__row__cell__groupbutton--open {
  background-color: var(--rood);
}

/* PRODUCER COLORCODE */
.order-detail-table__row__colorcode {
  height: 100%;
  width: 100%;
}

.order-detail-table__row__colorcode--dijkgraaf {
  background-color: hotpink;
}
.order-detail-table__row__colorcode--matthys {
  background-color: rgb(255, 217, 0);
}
.order-detail-table__row__colorcode--mvl {
  background-color: green;
}
.order-detail-table__row__colorcode--extern,
.order-detail-table__row__colorcode--keiretsu,
.order-detail-table__row__colorcode--ballondeco,
.order-detail-table__row__colorcode--usp,
.order-detail-table__row__colorcode--extern,
.order-detail-table__row__colorcode--asuvorm,
.order-detail-table__row__colorcode--daelmans {
  background-color: orange;
}

/* HQ APPROVE ALTER BUTTONS */
.order-detail-table__row__cell__alterbuttons {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  height: auto;
}

.order-detail-table__row__cell__alterbuttons__button {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--geel);
  font-size: 1.5rem;
  font-weight: 700;
}

/* ////////////////////////// */
/* TABLET PORTRAIT AND BIGGER */
/* ////////////////////////// */

@media screen and (min-width: 750px) {
  .order-detail-table__row td,
  .order-detail-table__row th {
    font-size: 16px;
    font-weight: 500;
    padding: 0.5rem;
    height: 8rem;
  }

  .order-detail-table__row th {
    font-size: 20px;
    padding: 0 1rem 2rem 1rem;
    height: 5rem;
  }

  /* COLUM SPECIFIC */
  .order-detail-table__body
    .order-detail-table__row
    .order-detail-table__row__cell:first-child {
    font-size: 1.2rem;
  }

  .order-detail-table__row__thumb {
    background-color: hotpink;
  }
}
