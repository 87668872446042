.manual {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.manual__toc {
  grid-area: 1 / 4 / 6 / 5;
}

.manual__toc__container {
  position: fixed;
  width: 350px;
  background-color: var(--blauw);
  color: white;
  padding: 1rem;
  font-weight: 500;
}

.manual__toc__container li {
  margin: 0.6rem 0;
}

.manual__text {
  grid-area: 1 / 1 / 6 / 4;
  padding-right: 3rem;
}

.manual > h1 {
  font-size: 2rem;
}

.manual__text > h2 {
  text-transform: none;
  color: var(--geel);
  font-weight: 700;
  font-size: 2rem;
  margin: 1.5rem 0 0.5rem 0;
  font-weight: 600;
}

.manual__text > h3 {
  text-transform: none;
  color: var(--blauw);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 1rem 0 0 0;
  font-weight: 600;
}

.manual__text > h4 {
  text-transform: none;
  color: var(--groen);
  font-weight: 700;
  font-size: 1.2rem;
  margin: 1rem 0 0 0;
  font-weight: 600;
}


.manual ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.manual ul {
  list-style-type: initial;
  list-style-position: inside;
}

li span{
font-weight: 600;
color: var(--groen_medium)
}

.manual__text__warning{
  font-weight: 600;
  margin: 1rem 0;
  display: flex;
  align-items: center;
}

.manual__text__warning__eyecather{
  color: var(--rood_light);
  font-weight: 700;
  font-size: 1.3rem;
  margin-right: 1rem;
}