/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.basket__header {
  margin-bottom: 2rem;
}

.basket__header__title {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

.basket__list {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.basket__list__item {
  display: grid;
  padding: 8px;
  text-align: left;
  border-top: solid 1px var(--zwart);
  border-bottom: solid 1px var(--zwart);
  grid-template-columns: 0 2fr 50px 80px;
  gap: 1rem;
}

.basket__list__item__picturebox {
  grid-area: 1 / 1 / 2 / 2;
  display: none;
}

.basket__list__item__text {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1rem;
  padding-right: 1rem;
}

.basket__list__item__text__reason {
  color: var(--blauw);
}

.basket__list__item__controls {
  grid-area: 1 / 3 / 2 / 4;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 0px;
  align-items: center;
}

.basket__list__item__delete {
  grid-area: 1 / 4 / 2 / 5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.basket__list__item__controls__alterbuttons__ammount {
  text-align: center;
  font-size: 1.5rem;
  width: 80%;
}

.basket__list__item__controls__alterbuttons__updatebutton {
  display: block;
  min-width: 2rem;
  font-weight: 700;
  font-size: 2rem;
  color: var(--blauw);
  width: 10%;
}

.basket__list .basket__list__item:nth-child(even) {
  background-color: var(--grijs_light);
}

.basket__list__item__delete {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.basket__footer {
  width: 100%;
}

.basket__footer__btn-group {
  display: flex;
  justify-content: space-between;
}

.basket__cta {
  font-weight: 500;
  min-width: 10rem;
  padding: 1rem;
  border: 1px var(--zwart) solid;
  margin-bottom: 1rem;
  background-color: var(--wit);
  color: var(--zwart);
}

.basket__cta--warning {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  margin-bottom: 1rem;
  background-color: var(--wit);
  color: var(--zwart);
}

.basket__cta .basket__cta--warning__yes {
  background-color: var(--zwart);
  color: white;
  font-weight: 700;
  margin-bottom: 0;
}

.basket__cta .basket__cta--warning__no {
  color: var(--rood);
  font-weight: 700;
  margin-bottom: 0;
}

.basket__cta--warning h3 {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.basket__cta--confirm {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: var(--groen);
  color: var(--wit);
  gap: 1rem;
}

.basket__cta--delete:hover {
  background-color: var(--rood);
  color: var(--zwart);
}

/* ///////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .basket__list__item {
    grid-template-columns: 100px 2fr 50px 80px;
  }

  .basket__list__item__picturebox {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .basket__list__item__picturebox__picture {
    display: block;
    max-height: 60px;
    max-width: 120px;
  }

  .basket__list__item__text {
    padding-right: 1rem;
  }

  .basket__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* /////////////////////////// */
/* TABLET LANDSCAPE AND BIGGER */
/* /////////////////////////// */

@media screen and (min-width: 996px) {
  .basket__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
  }

  .basket__list {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  .basket__cta {
    font-size: 1rem;
  }

  .basket__cta--confirm {
    width: auto;
    font-size: 1.2rem;
  }

  .basket__list__item {
    grid-template-columns: 150px 2fr 100px 80px;
  }

  .basket__list .basket__list__item:nth-child(even) {
    background-color: var(--grijs_light);
  }
}

/* ///////////////// */
/* LAPTOP AND BIGGER */
/* ///////////////// */

@media screen and (min-width: 1200) {
  .basket__list__item {
    grid-template-columns: 350px 2fr 200px 80px;
  }

  .basket__list__item__picturebox__picture {
    max-height: 100px;
    max-width: 300px;
  }

  .basket__list__item__delete {
    padding: 1rem;
    font-size: 1.5rem;
  }

  .basket__list .basket__list__item:nth-child(2) {
    font-size: 1.5rem;
  }

  .basket__list .basket__list__item:nth-child(even) {
    background-color: var(--grijs_light);
  }
}
