/* GLOBAL COLORS */
:root {
  --zwart: #000000;
  --geel_ultralight: #f9e6c5;
  --geel_light: #fbdba3;
  --geel: #fbb033;
  --geel_donker: #c27d07;
  --wit: #ffffff;
  --rood_ultralight: #f6dddd;
  --rood_light: #f5bfbf;
  --rood: #f25c5c;
  --grijs_light: #f2f2f2;
  --grijs: #acacac;
  --groen_ultralight: #d1ddb5;
  --groen_light: #9eab80;
  --groen: #3e5902;
  --groen_medium: #3e5951;
  --groen_dark: #121d01;
  --blauw_utlralight: #cbd4da;
  --blauw_light: #becbd3;
  --blauw: #254559;
  --blauw_medium: #191915;
  --adc_rood: #92002f;
  --adc_blauw: #23292b;
  --toastify-color-error: #f25c5c;
  --toastify-color-success: #9eab80;
  --toastify-color-warning: #fbb033;
  --toastify-color-info: #becbd3;
}

/* GLOBAL NORMALISE */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  font-family: inherit;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}

figure {
  margin: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}

ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

p {
  margin: 0;
}

cite {
  font-style: normal;
}

fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}

/* textarea:focus,
input:focus {
  outline: none;
  outline-color: var(--grijs);
} */

/* GLOBAL TYPOGRAHPY */
body,
textarea,
input {
  font-family: proxima-nova, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  color: var(--zwart);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.serif {
  font-family: freight-big-pro, serif;
  font-style: normal;
  color: var(--zwart);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-big-app-title {
  font-size: 5rem;
  font-weight: 500;
}

/* GLOBAL 10 COLUMN GRID */
.gridbox {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: 1fr;
}

.gridbox--two {
  grid-template-rows: 2fr;
}
