/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.table-base {
  border-collapse: collapse;
  width: 100%;
}

.table-base__row td,
.table-base__row th {
  font-size: 0.6rem;
  font-weight: 500;
  text-align: center;
  border-bottom: none;
  padding: 0.25rem;
}

.table-base__row td {
  border: solid 2px white;
}

.table-base__row th {
  font-size: 0.6rem;
  color: var(--blauw_light);
  text-align: center;
}

.table-base__body tr:nth-child(odd) {
  background-color: var(--grijs_light);
}

.table-base__body .table-base__row:hover {
  cursor: pointer;
  background-color: var(--groen_ultralight);
}

.table-base__row .table-base__row__actioncell {
  color: var(--geel);
  background-color: var(--geel_ultralight);
}
.table-base__row .table-base__row__actioncell__link {
  font-weight: 700;
  font-size: 0.6rem;
}

.table-base__row__setdelete {
  background-color: var(--rood);
  color: white;
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 996px) {
  .table-base__row td,
  .table-base__row th {
    font-size: 10px;
    padding: 0.3rem;
  }

  .table-base__row th {
    font-size: 10px;
  }

  .table-base__row .table-base__row__actioncell__link {
    font-size: 10px;
  }
}

/* ////////////////////////////// */
/* LAP- and DESKTOP == BIG SCREEN */
/* ////////////////////////////// */

@media screen and (min-width: 1300px) {
  .table-base__row td,
  .table-base__row th {
    font-size: 14px;
    padding: 0.5rem;
  }

  .table-base__row th {
    font-size: 1rem;
    padding: 0 1rem 1rem 1rem;
  }

  .table-base__row .table-base__row__actioncell {
    width: 5%;
  }
  .table-base__row .table-base__row__actioncell__link {
    font-size: 1rem;
  }
}
