.redirect-button {
  background-color: var(--geel);
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  font-size: 1rem;
  font-weight: 600;
}

.redirect-button:hover {
  background-color: var(--geel_donker);
  font-weight: 700;
}

.redirect-button--small {
  background-color: var(--geel);
  padding: 0.15rem 1rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.redirect-button:hover {
  background-color: var(--geel_donker);
  font-weight: 700;
}
