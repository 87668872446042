 .dropdown {
   position: relative;
   padding: 1rem 0;
 }

 .dropdown__name{
  font-size: 0.9rem;
 }
 
 .dropdown__cta {
   display: flex;
   justify-content: space-between;
 }
 
 .dropdown__cta__title {
   font-size: 1.5rem;
   font-weight: 400;
 }
 
 .dropdown__cta__button {
   transition: all 250ms ease-in-out;
 }
 
 .dropdown__cta__button--open {
   transform: rotate(45deg);
 }
 
 .dropdown__list {
   position: absolute;
   font-size: 1rem;
   background-color: var(--grijs_light);
   width: 100%;
   z-index: 50;
   top: 3rem;
   padding: 0.5rem;
   padding-left: 2rem;
   overflow-y: hidden;
 }
 
 .dropdown__list__item {
   margin: 0.5rem 0;
   font-weight: 600;
 }
 
 .dropdown__list__item:hover {
   color: var(--groen);
   cursor: pointer;
 }
 
 .dropdown__list__item--selected,
 .dropdown__selected {
   color: var(--geel);
   font-weight: 700;
 }
 
 .dropdown__selected {
   margin-right: 8px;
   text-transform: lowercase;
 }
 
 .dropdown__selected:hover {
   color: var(--rood);
   cursor: pointer;
 }
 