.image-uploader__item {
  background-color: var(--geel);
}

.image-uploader__cta-button {
  padding: 1rem;
  margin: 1rem;
  background-color: var(--geel);
  color: white;
}

.image-uploader__saving-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  color: black;
  background-color: white;
  z-index: 6000;
}

.image-uploader__saving-modal__list {
  background-color: var(--geel);
}

.image-uploader__saving-modal__list__item {
  padding: 1rem;
  margin: 1rem 0;
  background-color: var(--geel_light);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
