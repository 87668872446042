/* NAVBAR AVATAR */
.avatar__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .avatar__icon:hover {
    cursor: pointer;
  }
  
  .avatar__icon__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 2px solid;
  }