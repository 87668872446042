/* //////////// */
/* MOBILE FIRST */
/* //////////// */
.navbar {
  position: relative;
  height: 4rem;
  display: flex;
  left: 0;
  align-items: center;
  justify-content: space-between;
  background-color: var(--wit);
  padding: 1rem 0 0 1rem;
  z-index: 48;
  width: 100%;
}

.navbar__logo {
  width: 8rem;
}

.navbar__account__loggedinbox {
  display: flex;
  justify-content: flex-end;
}

.navbar__account__loggedinbox__info {
  padding-right: 1rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 1rem;
}

.navbar__account__loggedinbox__info__name {
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 500;
  line-height: 1.3;
  margin-right: 0.5rem;
  width: 100%;
}

.navbar__account__itemcount {
  position: absolute;
  font-size: 8px;
  top: 0rem;
  left: 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1rem;
  height: 1rem;
  background-color: var(--rood);
  color: var(--wit);
  font-weight: bold;
  border-radius: 50%;
  margin: 5px 0;
  border: 2px solid var(--wit);
}

.navbar__account__basket {
  position: relative;
}

.navbar__account__basket__icon {
  width: 24px;
}

/* ///////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .navbar {
    position: fixed;
    left: 200px;
    width: calc(100vw - 80px);
    padding: 0px 96px 0px 3rem;
    height: 7rem;
  }

  .navbar__logo {
    width: 10rem;
  }

  .navbar__toggler:hover {
    cursor: pointer;
  }

  .navbar__account__loggedinbox {
    display: flex;
    justify-content: flex-end;
  }

  .navbar__account__loggedinbox__info {
    padding-right: 3rem;
  }

  .navbar__account__loggedinbox__info__name {
    font-size: 1.5rem;
    margin-left: 1rem;
  }

  .navbar__account__itemcount {
    font-size: 12px;
    top: 1rem;
    left: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .navbar__account__basket__icon {
    width: 32px;
  }
}

/* //////////////////////////////////// */
/* TABLET LANDSCAPE == LAP- and DESKTOP */
/* //////////////////////////////////// */

@media screen and (min-width: 996px) {
  .navbar {
    padding: 0px 96px 0px 24px;
  }

  .navbar__account__loggedinbox__info__name {
    margin-left: 2rem;
  }
}

/* ////////// */
/* BIG SCREEN */
/* ////////// */

@media screen and (min-width: 1920px) {
  .navbar {
    padding: 0px 112px 0px 3rem;
  }


  .navbar__logo {
    width: 12rem;
  }

  .navbar__account__loggedinbox__info {
    padding-right: 4rem;
  }

  .navbar__account__loggedinbox__info__name {
    font-size: 2rem;
  }
}
