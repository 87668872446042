.searchbutton {
  height: 3rem;
  font-size: 20px;
  font-weight: 600;
  margin: 1rem 0;
  z-index: 1;
  color: var(--zwart);
}

.searchbutton--disabled {
  opacity: 0.5;
}

.searchbutton:hover {
  cursor: pointer;
}

.searchbutton__ammount {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  margin-left: 0.5rem;
  border-radius: 50%;
  background-color: var(--geel_light);
  border: 1px solid var(--geel);
}

.searchbutton__close {
  display: flex;
}

.searchbutton__close__icon {
  display: flex;
  align-self: start;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: var(--blauw_medium);
  padding: 8px;
  margin-right: 8px;
}
