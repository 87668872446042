/* STOREPAGE GENERIC LAYOUT */
.store-page {
  position: relative;
  width: 100%;
  height: 100%;
}

.store-page__content {
  position: relative;
  width: 100%;
}

/* STOREPAGE HEADER */
.store-page__content__header {
  background-color: var(--wit);
  top: 0px;
  padding-top: 1rem;
  min-height: 8rem;
}

.store-page__content__new-box {
  position: relative;
  width: 100%;
  min-height: 10vh;
}

.store-page__content__new-box__cancel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1rem;
  top: -1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  font-size: 2rem;
  background-color: var(--blauw_medium);
  z-index: 5;
}

.store-page__content__header__top {
  display: flex;
}

.store-page__content__header__top__title {
  width: 50%;
  font-size: 3rem;
  font-weight: 500;
}

.store-page__content__header__top__create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.store-page__content__header__top__create__box {
  padding: 0.5rem 0.75rem;
  background-color: var(--geel);
  margin-left: 1rem;
}
