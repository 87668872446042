/* MAIN FORM STYLING */
.store-form {
  background-color: var(--wit);
  border: 2px solid var(--rood_light);
  padding: 2rem 2rem 0 2rem;
  border-radius: 2rem;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-column-gap: 4rem;
  grid-row-gap: 2rem;
}

.store-form--valid {
  border: 2px solid var(--groen_light);
}

.store-form--inactive {
  border: 2px solid var(--zwart);
}

.store-form__item-container--left {
  grid-area: 1 / 1 / 2 / 2;
}

.store-form__item-container--right {
  grid-area: 1 / 2 / 2 / 3;
}

.store-form__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-area: 2 / 1 / 3 / 3;
  margin-bottom: 3rem;
}

.store-form__actions__save-container {
  display: flex;
  align-items: center;
}

.aware-of-trouble {
  font-size: 1.2rem;
}

.aware-of-trouble--checkbox {
  margin-left: 2rem;
  margin-right: 1rem;
}

.store-form__actions__add-address {
  position: relative;
  background-color: var(--geel);
  border-radius: 1rem;
  color: var(--wit);
  display: flex;
  align-items: center;
  padding: 0.25rem 2rem;
  margin: 0;
  font-size: 1.5rem;
  z-index: 5000;
}

.store-form__actions__remove-address{
  position: relative;
  background-color: var(--rood);
  border-radius: 1rem;
  color: var(--wit);
  display: flex;
  align-items: center;
  padding: 0.25rem 2rem;
  margin: 1rem 0 0 0;
  font-size: 1rem;
  z-index: 5000;
  font-weight: 600;
}

.store-form__actions__add-address__value {
  margin: 0 8px;
  font-weight: 600;
}


/* INPUTGROUP SPECIFIC STYLES */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px var(--grijs_light) inset !important;
}

.store-form__inputgroup {
  position: relative;
  min-height: 4rem;
  display: flex;
  font-size: 1rem;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;
  border-bottom: 2px solid var(--grijs_light);
}

.store-form__inputgroup:last-child {
  margin-bottom: 0rem;
  border-bottom: none;
}

.store-form__inputgroup--multiple {
  flex-direction: column;
}

.store-form__inputgroup__labelgroup {
  width: 35%;
  margin-bottom: 1rem;
}

.store-form__inputgroup__labelgroup label,
.store-form__inputgroup__labelgroup h2 {
  font-size: 1rem;
  font-weight: 600;
}

.store-form__inputgroup__labelgroup--multiple label {
  display: inline-block;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--blauw);
}

.store-form__inputgroup__labelgroup--multiple {
  margin-bottom: 0;
  width: 100%;
}

.store-form__inputgroup__labelgroup > p,
.store-form__inputgroup--multiple > p {
  font-size: 0.8rem;
}

.store-form__inputgroup__labelgroup .store-form__inputgroup__labelgroup--error {
  margin-top: 0;
  font-size: 0.8rem;
  color: var(--rood);
  font-weight: 500;
}

.form__inputgroup__labelgroup__extrainfo {
  display: flex;
  align-items: center;
}

.store-form__inputgroup__labelgroup
  .form__inputgroup__labelgroup__extrainfo
  .form__inputgroup__labelgroup__extrainfo__infolabel {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 1rem;
}

.store-form__inputgroup__multiple {
  font-size: 1.4rem;
  color: var(--blauw);
  width: 55%;
  border: none;
  font-weight: 600;
  padding: 0 0 2rem 0;
  background-color: var(--wit);
}

.store-form__inputgroup__multiple__singleline {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.store-form__inputgroup__multiple__double {
  width: 100%;
}

.store-form__inputgroup__inputfield {
  font-size: 1rem;
  color: var(--blauw);
  width: 55%;
  border: none;
  font-weight: 600;
  height: 2rem;
  background-color: var(--wit);
  padding: 0rem !important;
}


.store-form__inputgroup__inputfield--multiple {
  width: 100%;
}

.store-form__inputgroup__inputfield--active {
  background-color: var(--grijs_light);
  border-radius: 8px;
  border: 1px solid var(--grijs);
  padding: 0rem 1rem !important; 
}

.store-form__inputgroup__inputfield__clearbutton{
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  margin-left: 1rem;
  color: white;
  font-weight: 700;
  background-color: var(--geel);
}

/* CHECKBOX SPECIFIC */
.store-form__inputgroup--checkbox {
  width: 10%;
  align-items: center;
}

.store-form__inputgroup__inputfield--checkbox {
  height: 1.5rem;
  width: 1.5rem;
  background-color: var(--groen);
}
