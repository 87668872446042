/* //////////// */
/* MOBILE FIRST */
/* //////////// */
.account-container {
  position: fixed;
  height: 100%;
  width: 100vw;
}

.account-container__image {
  height: 30vh;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.account-container__image img {
  width: 100%;
}

.account-container__login {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
}

.account-container__login__logo {
  position: absolute;
  width: 8rem;
  top: 2rem;
  right: 2rem;
}

.account-container__login__modal__title {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.account-container__login__modal__title__point {
  color: var(--geel);
  font-size: 3rem;
}

.account-container__login__modal__form_input_btn {
  background-color: var(--zwart);
  color: var(--geel);
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0;
  margin-top: 1rem;
  border: 2px solid var(--zwart);
}

.account-container__login__modal__form_input_btn--negative {
  background-color: var(--wit);
  color: var(--geel);
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  margin: 1rem 0;
  margin-top: 1rem;
  border: 2px solid var(--geel);
}

.account-container__footer {
  font-size: 0.8rem;
  position: absolute;
  display: flex;
  bottom: 4rem;
  right: 2rem;
}

.account-container__footer__logo {
  width: 5rem;
  margin-left: 0.5rem;
}

/* ///////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .account-container__image {
    height: 60vh;
  }

  .account-container__login {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 8rem;
  }

  .account-container__login__logo {
    width: 10rem;
    top: 2rem;
    left: 2rem;
  }

  .account-container__login__modal__title {
    font-size: 4rem;
  }

  .account-container__login__modal__title__point {
    font-size: 4rem;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE*/
/* ////////////// */

@media screen and (min-width: 996px) {
  .account-container {
    display: flex;
  }

  .account-container__image {
    width: 60%;
    height: 100%;
  }

  .account-container__image img {
    width: 100%;
  }

  .account-container__login {
    position: relative;
    width: 40%;
    padding: 5rem 4rem;
  }

  .account-container__login__logo {
    width: 10rem;
    top: 5rem;
    left: 4rem;
  }

  .account-container__login__modal__title {
    font-size: 5rem;
    margin-bottom: 3rem;
  }

  .account-container__login__modal__title__point {
    color: var(--geel);
    font-size: 5.5rem;
  }

  .account-container__footer {
    right: 4rem;
  }
}

/* ////////////////////////////// */
/* LAP- and DESKTOP == BIG SCREEN */
/* ////////////////////////////// */

@media screen and (min-width: 1300px) {
  .account-container__login {
    padding: 5rem 8rem;
  }

  .account-container__login__logo {
    left: 8rem;
  }

  .account-container__footer {
    right: 8rem;
  }

  .account-container__footer__logo {
    width: 7rem;
  }
}
