.translation-manager__excelloader {
  width: 100%;
}

.translation-manager__excelloader__overlay {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 200;
}

.translation-manager__excelloader__container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
  height: 180px;
  z-index: 205;
}

.translation-manager__excelloader__template {
  width: 350px;
  right: 0;
  z-index: 206;
  padding: 2rem 2rem 2rem 2rem;
  border: 2px solid var(--grijs);
  border-radius: 2rem;
  background-color: white;
  margin-bottom: 2rem;
}

.translation-manager__excelloader__form {
  position: relative;
  display: block;
  z-index: 206;
}

.translation-manager__excelloader__form__fieldset {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.translation-manager__excelloader__form__fieldset__error {
  font-size: 1rem;
  color: var(--rood);
}

.translation-manager__excelloader__form__fieldset__group {
  display: flex;
  align-items: center;
}

.translation-manager__excelloader__form__fieldset__group label {
  margin-right: 2rem;
}

.translation-manager__excelloader__actionbuttons__save {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 4px 1rem;
  border-radius: 1rem;
}
