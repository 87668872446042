.squarebutton {
  color: white;
  background-color: black;
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  margin: 2rem 0;
  font-weight: 600;
  border-radius: 2rem;
}

.squarebutton__description {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
