/* PROFILE MENU */
.profile-overlay {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 6000;
  opacity: 1;
}

.profile-overlay--hidden {
  display: none;
  opacity: 0;
}

.profile {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 6001;
  height: 100%;
  background-color: var(--wit);
  padding: 3rem 0;
}

.profile__container {
  position: relative;
  width: 334px;
  height: 100%;
  border-left: solid 2px var(--grijs_light);
  padding-right: 1rem;
}

.profile__container__header {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.profile__container__content {
  padding: 3rem;
  padding-top: 0;
}

.profile__container__content__header {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.profile__container__content__header__name {
  font-size: 1.5rem;
}

.profile__container__content__header__email{
  font-size: 0.8rem;
}

.profile__container__content__divider {
  margin: 1rem 0;
}

.profile__container__content__divider h3 {
  color: #91a1ab;
  font-weight: 500;
}

.profile__container__content__divider p {
  font-size: 0.9rem;
}

/* PROFILE MENU AVATAR */
.profile__container__header__bigicon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid;
}

.history-hide {
  display: none;
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

@media screen and (min-width: 996px) {
  .history-hide {
    display: relative;
  }
}

/* ////////// */
/* BIG SCREEN */
/* ////////// */

@media screen and (min-width: 1920px) {
  .profile__container {
    width: 340px;
  }
}
