/* USERSPAGE GENERIC LAYOUT */
.users-page {
  position: relative;
  overflow: visible;
}

.users-page__content {
  width: 100%;
}

/* USERSPAGE HEADER */
.users-page__content__header {
  background-color: var(--wit);
  top: 0px;
  padding-top: 1rem;
  min-height: 8rem;
}

.users-page__content__new-box {
  position: relative;
  width: 100%;
  min-height: 10vh;
}

.users-page__content__new-box__cancel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -1rem;
  top: -1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  font-size: 2rem;
  background-color: var(--blauw_medium);
  z-index: 2000;
}

.users-page__content__header__top {
  display: flex;
}

.users-page__content__header__top__title {
  width: 50%;
  font-size: 3rem;
  font-weight: 500;
}

.users-page__content__header__top__create {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.users-page__content__header__top__create__box {
  padding: 0.5rem 0.75rem;
  background-color: var(--geel);
  margin-left: 1rem;
}

/* USERSPAGE DELETE MODAL */
.users-page__delete-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1500;
}

.users-page__delete-modal__content {
  font-size: 2rem;
  width: 50vw;
}

.users-page__delete-modal__content__title {
  font-size: 3rem;
}

.users-page__delete-modal__content__cta {
  width: 10rem;
  font-size: 2rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  background-color: var(--blauw);
  margin-top: 2rem;
  margin-right: 1rem;
}

.users-page__delete-modal__content__cta--cancel{
  background-color: var(--rood);
}