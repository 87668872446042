/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.dashboard {
  position: relative;
  display: block;
  min-height: 100vh;
}

.dashboard__header {
  position: relative;
}

.dashboard__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: hidden;
}

.dashboard__content__slidebox {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
}

.dashboard__side__menu__controls__list__item--disabled {
  opacity: 0.6;
}

.nabar-toggler {
  position: relative;
  z-index: 49;
}

/* /////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 641px) {
  .dashboard {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 112px auto;
  }

  .dashboard__header {
    grid-area: 1 / 2 / 2 / 3;
  }

  .dashboard__content {
    grid-area: 2 / 2 / 3 / 3;
  }

  .dashboard__content__slidebox {
    left: -48px;
    width: calc(100vw - 80px);
    padding: 0px 96px 3rem 72px;
    min-height: 100%;
  }

  .dashboard__side {
    position: relative;
    width: 200px;
    z-index: 49;
    grid-area: 1 / 1 / 3 / 2;
    background-color: var(--wit);
  }

  .dashboard__side__menu {
    position: fixed;
    width: 200px;
    display: flex;
    justify-content: space-between;
    padding: 3rem 0 3rem 1.5rem;
    height: 100vh;
    z-index: 10;
    background-color: var(--wit);
  }

  .dashboard__side__menu__controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .dashboard__side__menu__line {
    height: 100%;
    width: 2px;
    background-color: var(--grijs_light);
  }

  .dashboard__side__menu__controls__list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .dashboard__side__menu__controls__list__item {
    position: relative;
    margin: 1.5rem 0;
    height: 2rem;
  }

  .dashboard__side__menu__controls__list__item__box {
    position: relative;
    display: flex;
    align-items: center;
    transition: all 1s ease-in-out;
  }

  .dashboard__side__menu__controls__list__item__box__linkname {
    font-size: 1rem;
    color: var(--zwart);
    margin-left: 1rem;
  }

  .dashboard__side__menu__controls__list__item__box__linkname--selected {
    font-weight: 600;
    color: var(--groen);
  }

  .dashboard__side__menu__controls__list__item__countbullet {
    position: absolute;
    top: 0.1rem;
    left: 1.4rem;
    width: 1.2rem;
    height: 1.2rem;
    background-color: var(--rood_light);
    font-size: 0.7rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: solid 1px var(--rood);
    color: var(--rood);
  }
}

/* //////////////// */
/* TABLET LANDSCAPE */
/* //////////////// */

/* @media screen and (min-width: 996px) {
  
} */

/* ////////////////////////////// */
/* LAP- and DESKTOP == BIG SCREEN */
/* ////////////////////////////// */

@media screen and (min-width: 1300px) {
  .dashboard__header {
    grid-area: 1 / 2 / 2 / 3;
  }

  .dashboard__content {
    grid-area: 2 / 2 / 3 / 3;
  }

  .dashboard__content__slidebox {
    padding: 0px 128px 48px 96px;
  }
}
