.exceldownload {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.exceldownload__title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.exceldownload__form {
  display: flex;
  flex-direction: column;
}

.exceldownload__form__label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--geel_donker);
}

.exceldownload__form__label--error {
  color: red;
}

/* INPUTGROUP SPECIFIC STYLES */
.exceldownload__form__inputfield {
  font-size: 1.2rem;
  font-weight: 600;
  border: none;
  background-color: var(--geel);
  padding: 0.25rem;
  color: var(--wit);
  margin-bottom: 2rem;
}

.products-form__inputgroup__label--error {
  color: var(--rood);
  font-weight: 500;
}

.exceldownload__form__submit {
  background-color: var(--geel_light);
  font-size: 1rem;
  width: 100%;
  font-weight: 700;
  padding: 0.5rem 0;
  color: var(--geel_donker);
  transition: all 250ms ease-in-out;
  min-height: 3rem;
}

.exceldownload__form__submit--submitting {
  color: var(--rood);
  font-size: 1.2rem;
}
