.pagination-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  color: var(--blauw);
}

.pagination-bar__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.pagination-bar__group--left {
  align-items: flex-start;
}

.pagination-bar__group--right {
  display: block;
  align-items: flex-end;
  font-size: 1rem;
}

.pagination-bar__group--pagination {
  flex-direction: row;
}

.pagination-bar__group--pagination__item {
  font-size: 1rem;
  padding: 0 0.5rem;
  margin: 0 0.25rem;
}

.pagination-bar__group--pagination__item--active {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--groen);
}

.pagination-bar__group__pagecounter {
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination-bar__group__pagecounter__current,
.pagination-bar__group__pagecounter__total {
  width: 2rem;
  text-align: center;
}

.pagination-bar__group__pagecounter__current {
  text-align: right;
  color: var(--blauw);
  transition: all ease-in-out 300ms;
}

.pagination-bar__group__pagecounter__current--loading {
  color: var(--grijs_light);
}

.pagination-bar__group__pagecounter__total {
  font-size: 1rem;
  text-align: left;
}

.pagination-bar__group__pagecounter__divider {
  width: 2px;
  height: 1.5rem;
  margin: 0 1rem 0 1.5rem;
  background-color: var(--grijs_light);
}
