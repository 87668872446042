.buttons-panel{
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.downloadpdf-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: var(--geel);
  animation: bounce 2s infinite alternate;
  margin: 1rem;
}

.downloadpdf-button:hover{
  animation: step-start;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
