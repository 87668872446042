/* MAIN FORM STYLING */
.users-form {
    background-color: var(--wit);
    border: 2px solid var(--rood);
    padding: 2rem;
    border-radius: 2rem;
    margin-bottom: 2rem;
    background-color: white;
  }
  
  .users-form--valid {
    border: 2px solid var(--groen);
  }
  
  .users-form__fields {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    column-gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .users-form__actions {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
  
  .aware-of-trouble {
    font-size: 1.2rem;
  }
  
  .aware-of-trouble--checkbox {
    margin-left: 2rem;
    margin-right: 1rem;
  }
    
  .users-form__inputgroup {
    display: flex;
    font-size: 1.2rem;
    flex-direction: column;
    width: 50%;
  }
  
  .users-form__inputgroup label {
    height: 2rem;
    margin-top: 1rem;
  }
  
  .users-form__inputgroup__inputfield {
    font-size: 1rem;
    width: 100%;
    border: none;
    background-color: var(--grijs_light);
    padding: 0.25rem;
  }
  
  .users-form__inputgroup__label--error {
    color: var(--rood);
    font-weight: 500;
  }
  
  /* CHECKBOX SPECIFIC */
  .users-form__inputgroup--checkbox {
    width: 10%;
    align-items: center;
  }
  
  .users-form__inputgroup__inputfield--checkbox {
    height: 2rem;
    width: 2rem;
    background-color: var(--groen);
  }
  