.delete-button-container {
  position: relative;
}

.delete-button-modal {
  position: absolute;
  width: 20rem;
  color: var(--wit);
  font-size: 2rem;
  right: 3rem;
  font-weight: 600;
  background-color: black;
  padding: 1rem;
  z-index: 50;
}

.delete-button {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
}

.delete-button__value {
  margin: 0;
  font-weight: 600;
  color: var(--geel);
}

.delete-button--shake {
  animation: shake-checkout 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake-checkout {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}
