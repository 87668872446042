/* //////////// */
/* MOBILE FIRST */
/* //////////// */

.orders-detail-page {
  padding-bottom: 3rem;
}

/* HEADER */
.orders-detail-page__header__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: solid 2px var(--zwart);
}

.orders-detail-page__header__info__title {
  font-size: 2rem;
  flex-grow: 2;
}

.orders-detail-page__header__info__title:hover {
  cursor: pointer;
}

.orders-detail-page__header__info__title__language {
  font-size: 0.8rem;
}

.orders-detail-page__header__info__description {
  text-align: left;
  font-size: 0.8rem;
  flex-grow: 1;
}

.orders-detail-page__header__comment-box {
  background-color: var(--blauw_light);
  padding: 1rem;
}

.order-detail-page__header__grouping-switcher {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-detail-page__header__grouping-switcher__parcel {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.order-detail-page__header__grouping-switcher__parcel {
  display: flex;
  font-size: 1.4rem;
  gap: 1rem;
  align-items: center;
}

/* FOOTER */
.orders-detail-page__footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/* /////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 641px) {
  .orders-detail-page {
    padding-bottom: 5rem;
  }

  /* HEADER */
  .orders-detail-page__header__info__title {
    font-size: 3rem;
  }

  .orders-detail-page__header__info__title:hover {
    cursor: pointer;
  }

  .orders-detail-page__header__info__title__language {
    font-size: 1rem;
  }

  .orders-detail-page__header__info__description {
    text-align: right;
    font-size: 1rem;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE*/
/* ////////////// */

@media screen and (min-width: 996px) {
  .orders-detail-page {
    padding-bottom: 5rem;
  }

  /* HEADER */
  .orders-detail-page__header__info__title {
    font-size: 3rem;
  }

  .orders-detail-page__header__info__title:hover {
    cursor: pointer;
  }

  .orders-detail-page__header__info__title__language {
    font-size: 1rem;
  }

  .orders-detail-page__header__info__description {
    text-align: right;
  }
}

/* //////////////// */
/* LAP- and DESKTOP */
/* //////////////// */

@media screen and (min-width: 1300px) {
  .orders-detail-page {
    padding-bottom: 5rem;
  }

  /* HEADER */
  .orders-detail-page__header__info__title {
    font-size: 3rem;
  }

  .orders-detail-page__header__info__title:hover {
    cursor: pointer;
  }

  .orders-detail-page__header__info__title__language {
    font-size: 1rem;
  }

  .orders-detail-page__header__info__description {
    text-align: right;
  }
}

/* ////////// */
/* BIG SCREEN */
/* ////////// */

@media screen and (min-width: 1920px) {
  .orders-detail-page {
    padding-bottom: 5rem;
  }

  /* HEADER */
  .orders-detail-page__header__info__title {
    font-size: 3rem;
  }

  .orders-detail-page__header__info__title:hover {
    cursor: pointer;
  }

  .orders-detail-page__header__info__title__language {
    font-size: 1rem;
  }

  .orders-detail-page__header__info__description {
    text-align: right;
  }
}
