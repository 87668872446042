/* //////////// */
/* MOBILE FIRST */
/* //////////// */
.backorderpage {
  position: relative;
  min-height: 50vh;
  padding-bottom: 2rem;
  width: 100%;
}

.backorderpage__header__title {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.backorderpage__header__descr {
  text-align: right;
}

.backorderpage__unorderable {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.backorderpage__unorderable__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50vw;
  background-color: var(--blauw_medium);
  color: var(--wit);
  font-size: 1.2rem;
  text-align: center;
  padding: 4rem;
}

.backorderpage__loadingcontainer {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ///////////////// */
/* TABLET PORTRAIT */
/* /////////////// */

@media screen and (min-width: 750px) {
  .backorderpage__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

/* //////////////// */
/* TABLET LANDSCAPE*/
/* ////////////// */

@media screen and (min-width: 996px) {
  .backorderpage__header__title {
    font-size: 3rem;
  }
}
